import './App.css';
import CryptoJS from 'crypto-js';
import { useState, useEffect } from 'react';

let fetched = false;

function isValidRedirectUrl(url, trustedDomains) {
    const parsedUrl = new URL(url);

    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
        console.error('Invalid redirect URL protocol.');
        return false;
    }

    if (!trustedDomains.includes(parsedUrl.hostname)) {
        console.error('Untrusted redirect URL domain.');
        return false;
    }

    return true;
}

function redirectToRedirectUrl(url, trustedDomains) {
    if (url && isValidRedirectUrl(url, trustedDomains)) {
        window.location.href = url;
    } else {
        window.errorModal.showModal()
        console.error('Redirect URL not found or is invalid.');
    }
}

function App() {
    const [canLogin, setCanLogin] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [agreePanelStyle, setAgreePanelStyle] = useState({
        backgroundImage: window.innerWidth < 821 ? "linear-gradient(rgba(229,231,235,0.75), rgba(229,231,235,0.75)), url('mobile-background.jpg')" : ""
    });

    const handleResize = () => {
        setAgreePanelStyle({
            backgroundImage: window.innerWidth < 821 ? "linear-gradient(rgba(229,231,235,0.75), rgba(229,231,235,0.75)), url('mobile-background.jpg')" : ""
        });
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const directTo = urlParams.get('directTo');
    //const metaData = JSON.parse(urlParams.get('metaData'));
    const appId = urlParams.get('appId');
    if(appId && !fetched) {
        fetch(`https://p0.kamiya.dev/api/session/app/${appId}`)
            .then(res => res.json())
            .then(res => {
                if(res.status === 200) {
                    fetched = true;
                    setCanLogin(true);
                    setMetaData(res.data);
                }
            });
    }
    return (
      <>
          <div className="navbar bg-base-100 fixed">
              <div className="flex-1">
                  <a className="btn btn-ghost normal-case text-xl">Kamiya ID Login</a>
              </div>
              <div className="flex-none">
                  <label className="btn btn-ghost btn-circle avatar">
                      <div className="w-10 rounded-full">
                          <img alt={email ? email : 'profile image'} src={email ? 'https://cravatar.cn/avatar/' + CryptoJS.MD5(email.toLowerCase()).toString() : 'https://m.360buyimg.com/babel/jfs/t1/131046/29/29701/23415/63a23eb1E6ebac265/752741f63595b14a.png'} />
                      </div>
                  </label>
              </div>
          </div>
          <div className="md:grid md:grid-cols-12">
              <div className="hero min-h-screen bg-base-200 col-span-9 hidden md:block" style={{backgroundImage: `url('./desktop-background.jpg')`}}>
              </div>
              <div className="hero min-h-screen bg-base-200 md:col-span-3" style={agreePanelStyle}>
                  <ul className="steps mb-112 md:mb-128">
                      <li data-content="✓" className="step step-neutral">Login</li>
                      <li data-content="✓" className="step step-neutral">Auth</li>
                      <li data-content="●" className="step step-neutral">Agree</li>
                  </ul>
                  <div className="hero-content text-center">
                      <div className="max-w-md">
                          <h1 className="text-5xl font-bold">欢迎</h1>
                          <h2 className="text-2xl font-bold py-4">一切准备就绪</h2>
                          <p className="py-6">以 {email} <br />登录到 <span className="text-primary-focus">{metaData.name ? metaData.name : 'null'}</span><br /><br />{metaData.isSystem ? <span>该应用为经过验证的Kamiya应用</span> : null }</p>
                          {canLogin ? <button className="btn btn-primary" onClick={() => {redirectToRedirectUrl(directTo, metaData.domains)}}>确认登录</button> : <button className="btn btn-primary"><span className="loading loading-spinner"></span>loading</button>}
                      </div>
                  </div>
              </div>
          </div>
          <dialog id="errorModal" className="modal modal-bottom sm:modal-middle">
              <form method="dialog" className="modal-box">
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                  <h3 className="font-bold text-lg">抱歉</h3>
                  <p className="py-4">出现了一些问题，我们无法使你登录到目标应用。</p>
              </form>
          </dialog>
      </>
    );
}

export default App;
